<template>
  <div id="testResult" class="grey lighten-3 fill-height">
    <v-container>
      <!-- title -->
      <v-row justify="center">
        <h1 class="text-title my-9">
          <v-icon class="text-h3 mr-2" color="green darken-1">
            mdi-table-star
          </v-icon>
          Result: {{ test.testName }}
        </h1>
      </v-row>

      <v-divider class="mt-3"></v-divider>

      <!-- IF ERROR -->
      <v-row v-if="error" justify="center" align="center" class="text-h6 my-3">
        <v-alert type="error">{{ error }}</v-alert>
      </v-row>

      <template v-else>
        <!-- Courses -->
        <v-row justify="center" align="center" class="text-h6 my-3">
          <span class="mr-1"> Courses: </span>
          <v-chip
            v-for="(courses, i) in test.selectedCourses"
            :key="i"
            class="text-capitalize ma-1"
            color="primary"
          >
            {{ courses }}
          </v-chip>
        </v-row>

        <v-divider class="my-2"></v-divider>
        <!-- DateTime -->
        <v-row justify="space-around" class="mt-5">
          <span>
            <b>Starts at:</b>
            {{ dateTimeText(test.startDateTime.seconds * 1000) }}
          </span>
          <span>
            <b>Ends at:</b>
            {{ dateTimeText(test.endDateTime.seconds * 1000) }}
          </span>
          <span>
            <b>Duration:</b>
            {{ test.testDuration }} minutes
          </span>
        </v-row>

        <!-- Result Cards -->
        <v-row
          v-if="
            !superLoading &&
            testObjLocal != null &&
            allQuestions != null &&
            Object.keys(allQuestions).length > 0 &&
            testScoresObj != null
          "
          justify="center"
          class="mt-6"
        >
          <ResultCard
            :superLoading="superLoading"
            :test="testObjLocal"
            :allQuestions="allQuestions"
            :testScoresObj="testScoresObj"
          />
        </v-row>
      </template>
    </v-container>
  </div>
</template>

<script>
const dayjs = require("dayjs");

import ResultCard from "@/components/Home/View/Results/ResultCard.vue";

export default {
  name: "TestResult",
  props: ["test"],
  metaInfo: {
    title: "Test Result",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "Test Result Page description lorem ipsum dolor sit amet.",
      },
    ],
  },
  components: {
    ResultCard,
  },
  data: () => ({
    superLoading: true,
    error: "",
    allQuestions: null,
    testScoresObj: null,
    testObjLocal: null,
  }),
  methods: {
    setSuperLoading(value) {
      this.superLoading = value;
    },
    checkTestExists() {
      if (
        !this.test ||
        (this.test.constructor !== Object &&
          Object.keys(this.test).length === 0)
      ) {
        this.$router.push("/home");
      } else {
        this.testObjLocal = this.test;
        this.fetchTestData(this.test.selectedQuestions, this.test.id);
      }
    },

    fetchTestData(selectedQuestions, testID) {
      this.setSuperLoading(true);
      this.$store
        .dispatch("getTestQuestions", selectedQuestions)
        .then((res) => {
          this.allQuestions = res.questionsDataObj;

          //replace folders in testObjLocal.selectedQuestions with array of questions
          //(or in other words: make it like manually selected Questions)
          //for the respective folder
          Object.keys(this.testObjLocal.selectedQuestions).forEach(
            (section) => {
              //check if it is not an array: then it is an obj {folderName: ''}
              if (
                this.testObjLocal.selectedQuestions[section].constructor !==
                Array
              ) {
                let folderName =
                  this.testObjLocal.selectedQuestions[section].folderName;

                this.$set(
                  this.testObjLocal.selectedQuestions,
                  section,
                  res.folderQuestions[folderName]
                );
              }
            }
          );

          this.$store
            .dispatch("getTestScoresDoc", testID)
            .then((res) => {
              this.testScoresObj = res;
              this.superLoading = false;
            })
            .catch((err) => {
              console.log(err);
              this.error = err;
              this.superLoading = false;
            });
        })
        .catch((err) => {
          console.log(err);
          this.error = err;
          this.superLoading = false;
        });
    },

    dateTimeText(timestamp) {
      return dayjs(timestamp).format("hh:mm a, D MMM");
    },
  },
  mounted() {
    this.checkTestExists();
  },
};
</script>